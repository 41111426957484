<template>
    <div>
        <v-dialog v-model="appStore.modal" persistent overlay style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    margin: 0;
                    z-index: 2000;
                    background: rgb(0,0,0,0.5);
                ">
            <v-row justify="center">
                <v-card style="
            padding: 32px;
            width: 450px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
          ">
                    <!-- Imagem Centralizada -->
                    <div class="d-flex justify-center align-center mb-8" style="height: 120px;">
                        <img src="/assets/nxlite/vue/operadores-x-recurso/app/components/nx_circulo_azul.png"
                            alt="Imagem NX" style="max-height: 100px; max-width: 100%; border-radius: 50%;" />
                    </div>

                    <v-select label="Selecione o Dash" :items="items" v-model="select" item-title="nome_dashboard"
                        item-value="name" @update:modelValue="set_dash"
                        style="width: 100%; margin-bottom: 16px;"></v-select>

                    <div v-if="appStore.group_by?.senha_dashboard" style="width: 100%;">
                        <!-- Campo de Senha -->
                        <v-text-field v-model="password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="visible ? 'text' : 'password'" placeholder="Digite Sua Senha"
                            prepend-inner-icon="mdi-lock-outline" density="comfortable" variant="outlined"
                            style="width: 100%; height: 50px; margin-bottom: 16px;"
                            @click:append-inner="visible = !visible"></v-text-field>

                        <!-- Botão login -->
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-btn @click="login" size="large" elevation="5"
                                    style="background-color: #007bff; color: white; border-radius: 25px; font-weight: bold; width: 200px;">
                                    <small style="font-size: 18px;">Login</small>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-row>

        </v-dialog>
    </div>
</template>



<script>
import { useAppStore } from "../../stores/app";

export default {
    name: "Modal",
    data: () => ({
        items: [],
        select: null,
        visible: true,
        password: "",
        modal: null,
    }),
    computed: {
        appStore() {
            return useAppStore();
        },
    },
    async beforeMount() {
        this.items = await this.configsSet();
        console.log("beforeMount", this.items);
    },
    methods: {
        itemProps(selection) {
            console.log(selection);
            return selection;
        },
        async set_dash(selection) {
            console.log("set_dash", selection);
            const cur_selection = this.items.find((i) => i.name == selection);
            console.log("set_dash (not implemented)", cur_selection);
            this.appStore.group_by = cur_selection;
            this.appStore.selectedDashboardName = cur_selection.nome_dashboard; // Add this line

            if (!this.appStore.group_by.senha_dashboard) {
                this.appStore.modal = false;
                await this.populate_data();
            }
        },
        openModal() {
            this.appStore.modal = true;
        },
        closeModal() {
            this.appStore.modal = false;
        },
        setPassword({ target }) {
            console.log("data", target.value);
            this.password = target.value;
        },
        async populate_data() {
            await this.appStore.populate_by_config();
        },
        async login() {
            if (this.appStore.group_by.senha_dashboard === this.password) {
                console.log("senha correta");
                this.appStore.modal = false;
                await this.populate_data();
            } else {
                console.error("Senha incorreta");
            }
        },
        async configsSet() {
            const all_configs = await frappe.db.get_list("Configuracao Dashboard Web");
            const detailed = await all_configs.map(async ({ name }) =>
                await frappe.db.get_doc("Configuracao Dashboard Web", name)
            );
            console.log("all_configs", await detailed);
            this.configs = await Promise.all(detailed);
            return this.configs;
        },
        
    },
};
</script>
