<template>
	<div class="nav">
		<v-container fluid>
			<!-- formatação dos cards -->
			<v-row justify="start" class="mt-2" >
				<v-col
					class="pt-0 pb-0 mt-0 mb-4"
					style="height: 70%; justify-items: center !important; "
					cols="12"
					sm="6"
					md="4"
					lg="3"
					
					xxl="2"
					
					
					v-if="appStore.cards"
					v-for="(card, index) in appStore.cards"
					:key="card.name"
					v-bind="card"
				>
					<Card :card="card" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import Card from "./card.vue";
import { useAppStore } from "../../stores/app";
import Navbar from "./navbar.vue";

export default {
	name: "OverlayEvents",
	components: { Card, Navbar },
	computed: {
		appStore() {
			return useAppStore();
		},
	},
	methods: {
		openLoginModal() {
			// Abre o modal de login
			this.appStore.showLoginModal = true;
		},
		closeLoginModal() {
			// Fecha o modal de login
			this.appStore.showLoginModal = false;
		},
		submitLogin() {
			// Lógica de login aqui (exemplo: autenticar usuário)
			alert("Login enviado!");
			this.closeLoginModal();
		},
	},
};
</script>

<style scoped>

.nav {
	margin-top: 70px;
}
</style>
