import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Modal = _resolveComponent("Modal")
  const _component_OverlayEvents = _resolveComponent("OverlayEvents")
  const _component_Navbar = _resolveComponent("Navbar")

  return (_openBlock(), _createElementBlock("div", null, [
    _createCommentVNode(" <Card/> "),
    _createVNode(_component_Modal),
    _createVNode(_component_OverlayEvents),
    _createVNode(_component_Navbar)
  ]))
}