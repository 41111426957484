import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b759e6d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-center content-center" }
const _hoisted_2 = { style: {"font-weight":"bold","font-size":"15px"} }
const _hoisted_3 = { class: "d-flex align-center" }
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_6 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_7 = {
  key: 0,
  class: "d-flex align-center"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_ejs_daterangepicker = _resolveComponent("ejs-daterangepicker")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_e_column = _resolveComponent("e-column")
  const _component_e_columns = _resolveComponent("e-columns")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_tooltip = _resolveComponent("v-tooltip")
  const _component_ejs_grid = _resolveComponent("ejs-grid")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appStore.showModalHistorico,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($options.appStore.showModalHistorico) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar, { color: "primary" }),
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            fluid: "",
            class: "mt-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "pb-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_toolbar, {
                        height: "80",
                        dark: "",
                        color: "#37474F"
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            variant: "flat",
                            class: "ml-3 mr-2 py-0 align-center",
                            color: "secondary",
                            height: "42",
                            onClick: $options.OnSearchHistoricos
                          }, {
                            default: _withCtx(() => [
                              (
                    $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl'
                  )
                                ? (_openBlock(), _createBlock(_component_v_card_text, {
                                    key: 0,
                                    class: "py-0 font-weight-bold text-h5"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("PESQUISAR")
                                    ]),
                                    _: 1 /* STABLE */
                                  }))
                                : _createCommentVNode("v-if", true),
                              _createVNode(_component_v_icon, {
                                icon: "mdi-magnify",
                                size: "35"
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            onClick: _cache[0] || (_cache[0] = $event => ($options.onVoltar())),
                            color: "#EEEEEE",
                            class: "ml-3 mr-2 py-0 align-center",
                            variant: "flat",
                            height: "42"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                icon: "mdi-arrow-left-circle",
                                size: "35"
                              }),
                              ($data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl')
                                ? (_openBlock(), _createBlock(_component_v_card_text, {
                                    key: 0,
                                    class: "py-0 font-weight-bold text-h5"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("VOLTAR")
                                    ]),
                                    _: 1 /* STABLE */
                                  }))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_toolbar_title, { class: "font-weight-bold" }, {
                            default: _withCtx(() => [
                              _createTextVNode("HISTÓRICOS ANTERIORES")
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createElementVNode("div", null, [
                            _createVNode(_component_v_checkbox, {
                              style: {"color":"white","font-weight":"bold"},
                              class: "mt-5 pt-0 pb-0 mr-4",
                              label: $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl'
                  ? 'FALTANDO INFORMAÇÕES'
                  : 'FALT. INFOS.'
                  ,
                              modelValue: $options.appStore.filtro_apontamentos_faltando_informacoes,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($options.appStore.filtro_apontamentos_faltando_informacoes) = $event))
                            }, null, 8 /* PROPS */, ["label", "modelValue"])
                          ]),
                          _createElementVNode("div", null, [
                            _createVNode(_component_ejs_daterangepicker, {
                              ref: "dateRangePicker",
                              style: {"height":"40px","font-size":"16px","font-weight":"600","width":"100%"},
                              format: 'dd/MM/yyyy',
                              value: $data.defaultDateRange,
                              onKeydown: $options.applyDateRangeFormat,
                              onClick: $options.showCalendar,
                              onChange: $options.onDateChange
                            }, null, 8 /* PROPS */, ["value", "onKeydown", "onClick", "onChange"]),
                            _createCommentVNode(" <ejs-daterangepicker\n                                     ref=\"dateRangePicker\" style=\"height: 40px; font-size: 16px; font-weight: 600; width: 100%;\"\n                                    :format=\"'dd/MM/yyyy'\" :value=\"defaultDateRange\" \n                                    @change=\"onDateChange\"></ejs-daterangepicker> ")
                          ])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "pt-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ejs_grid, {
                            dataSource: $options.appStore.apontamentos_historico,
                            height: $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl'
                ? 'calc(100vh - 240px)'
                : '60vh'
                ,
                            width: "100%",
                            rowDataBound: $options.onRowDataBound,
                            pageSize: "20",
                            enableHover: false,
                            allowPaging: true,
                            ref: "ejsGrid2",
                            allowSelection: false,
                            id: "histHoje"
                          }, {
                            statusTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", _hoisted_1, [
                                ($options.verify_status_sensor(data.status_sensor))
                                  ? (_openBlock(), _createBlock(_component_v_card, {
                                      key: 0,
                                      color: $options.status_color(data.status_sensor),
                                      style: {"min-width":"70px","height":"61px","align-content":"center","border-radius":"0px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "40" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-lan-connect")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["color"]))
                                  : (_openBlock(), _createBlock(_component_v_card, {
                                      key: 1,
                                      color: '#37474F',
                                      style: {"min-width":"70px","height":"61px","align-content":"center","border-radius":"0px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "40" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-lan-disconnect")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 1 /* STABLE */
                                    }))
                              ])
                            ]),
                            dt_inicio_template: _withCtx(({ data }) => [
                              _createElementVNode("small", _hoisted_2, _toDisplayString($options.retornaDataFormatada(data.dt_inicio)), 1 /* TEXT */)
                            ]),
                            ordem_producao: _withCtx(({ data }) => [
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_v_btn, {
                                  variant: "text",
                                  size: "35",
                                  onClick: $event => ($options.ShowOrdemDeProducaoHistorico(data)),
                                  color: "secondary",
                                  class: "mr-1 ml-2 d-flex justify-right"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, {
                                      color: "white",
                                      size: "35"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi-magnify")
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                                (data.ordem_de_producao)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      variant: "text",
                                      size: "35",
                                      onClick: $event => ($options.ShowDetalheOS(data)),
                                      color: "secondary",
                                      class: "mx-1"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, {
                                          color: "white",
                                          size: "35"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-folder-open")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                                  : _createCommentVNode("v-if", true),
                                _createVNode(_component_v_card_text, { class: "pa-0 text-start text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(data.ordem_de_producao), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ])
                            ]),
                            columnTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_v_btn, {
                                  size: "60",
                                  flat: "",
                                  onClick: $event => ($options.ShowApontamento(data)),
                                  color: "transparent"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, { size: "35" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi mdi-file-edit-outline")
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                              ])
                            ]),
                            inicioTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", null, _toDisplayString($options.retornaDataFormatada(data.dt_inicio)), 1 /* TEXT */)
                            ]),
                            fimTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", null, _toDisplayString($options.retornaDataFormatada(data.dt_fim)), 1 /* TEXT */)
                            ]),
                            motivoParada: _withCtx(({ data }) => [
                              (data.tipo === 'Parada')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createVNode(_component_v_btn, {
                                      size: "40",
                                      flat: "",
                                      onClick: $event => ($options.ShowMotivoDeParada(data)),
                                      color: "transparent"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "35" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-magnify")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                                    _createVNode(_component_v_card_text, { class: "pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.desc_motivo_parada), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ]),
                            turno: _withCtx(({ data }) => [
                              (data.tipo === 'Parada')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createVNode(_component_v_card_text, { class: "pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.turno), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ]),
                            operador: _withCtx(({ data }) => [
                              (data.tipo === 'Parada')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createVNode(_component_v_card_text, { class: "pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.nome_operador), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ]),
                            obsTemplate: _withCtx(({ data }) => [
                              _createVNode(_component_v_tooltip, {
                                text: data.observacao
                              }, {
                                activator: _withCtx(({ props }) => [
                                  _createElementVNode("div", _normalizeProps(_guardReactiveProps(props)), [
                                    _createVNode(_component_v_card_text, { class: "px-2 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.observacao), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ], 16 /* FULL_PROPS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_e_columns, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_e_column, {
                                    headerText: "EDITAR",
                                    field: "status",
                                    template: 'columnTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "SENSOR",
                                    template: 'statusTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "STATUS PRODUÇÃO",
                                    field: "tipo",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "INÍCIO",
                                    template: 'inicioTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "FIM",
                                    template: 'fimTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OP",
                                    template: 'ordem_producao',
                                    field: "ordem_de_producao",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "TURNO",
                                    field: "turno",
                                    template: 'turno',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "MOTIVO DE PARADA",
                                    template: 'motivoParada',
                                    field: "desc_motivo_parada",
                                    textAlign: "Left"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OPERADOR",
                                    field: "nome_operador",
                                    template: 'operador',
                                    textAlign: "center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OBSERVAÇÃO",
                                    template: 'obsTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createTextVNode("> "),
                                  _createVNode(_component_e_column, {
                                    headerText: "APONTAMENTO",
                                    field: "name",
                                    textAlign: "Center"
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["dataSource", "height", "rowDataBound"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}