import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4c783ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"font-size":"20px"} }
const _hoisted_2 = { style: {"font-size":"20px"} }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "upper-section" }
const _hoisted_5 = { class: "image-container" }
const _hoisted_6 = { style: {"width":"100%"} }
const _hoisted_7 = {
  key: 0,
  class: "mb-1"
}
const _hoisted_8 = { style: {"font-size":"17px","font-weight":"500"} }
const _hoisted_9 = { class: "mb-1" }
const _hoisted_10 = { style: {"font-size":"17px","font-weight":"500"} }
const _hoisted_11 = {
  key: 1,
  class: "mb-1"
}
const _hoisted_12 = { style: {"font-size":"17px","font-weight":"500"} }
const _hoisted_13 = { class: "lower-section" }
const _hoisted_14 = { style: {"font-size":"17px","font-weight":"500"} }
const _hoisted_15 = { style: {"font-size":"17px","font-weight":"500"} }
const _hoisted_16 = { style: {"font-size":"17px","font-weight":"500"} }
const _hoisted_17 = {
  key: 0,
  style: {"height":"0px"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_img = _resolveComponent("v-img")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "border-solid border-opacity-100 border-md",
    rounded: "lg",
    width: "100%",
    elevation: "7",
    type: $options.appStore.group_by.tipo_dashboard == 'Operadores da Lista' ? 'operador' : 'recurso'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createCommentVNode(" titulo do card "),
          _createElementVNode("div", {
            style: _normalizeStyle({
					color: 'white',
					backgroundColor: $options.cardStatus.color,
					borderRadius: '5px',
					textAlign: 'center',
					fontSize: '20px',
					border: '2px solid #212121',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '8px',
					minWidth: '100%',
					overflow: 'ellipsis',
					flexWrap: 'wrap',
				})
          }, [
            _createCommentVNode(" troca de icone quando tiver ativo ou desativado "),
            _createVNode(_component_v_icon, {
              class: _normalizeClass($options.isActive ? 'mdi mdi-play-circle' : 'mdi mdi-pause-circle'),
              style: {"font-size":"25px"}
            }, null, 8 /* PROPS */, ["class"]),
            _createElementVNode("small", _hoisted_1, _toDisplayString($options.cardStatus.dateTime), 1 /* TEXT */),
            _createVNode(_component_v_icon, {
              class: "mdi mdi-timer",
              style: {"height":"2px"}
            }),
            _createElementVNode("small", _hoisted_2, _toDisplayString($options.cardStatus.color === "gray" ? "-" : $data.currentHour), 1 /* TEXT */)
          ], 4 /* STYLE */)
        ]),
        _: 1 /* STABLE */
      }),
      _createCommentVNode(" indicadores do card "),
      _createVNode(_component_v_card_title, { style: {"text-align":"center","font-size":"25px"} }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($data.group_by == "operador" ? $data.card.nome : $data.card.nome), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createCommentVNode(" Corpo do card "),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_img, {
              src: $options.iconPath,
              alt: "imagem operador ou recurso",
              class: "card-img",
              white: "60"
            }, null, 8 /* PROPS */, ["src"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            ($options.appStore.group_by.tipo_dashboard !== 'Operadores da Lista')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_v_card, {
                    style: {"background-color":"#e0e0e0","color":"black"},
                    elevation: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        title: "Recurso",
                        style: {"color":"black","font-weight":"bold","font-size":"18px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi mdi-cog-outline",
                            class: "text-truncate",
                            style: {"margin-right":"8px"}
                          }),
                          _createElementVNode("small", _hoisted_8, _toDisplayString(($data.card.apontamentos &&
											$data.card.apontamentos.find(
												(apontamento) => apontamento.desc_recurso
											)?.desc_recurso) ||
										" -"), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" OP card "),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_v_card, {
                style: {"background-color":"#e0e0e0","color":"black"},
                elevation: "5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, {
                    title: "OP",
                    style: {"color":"black","font-weight":"bold","font-size":"18px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: "mdi mdi-note-text-outline",
                        class: "text-truncate",
                        style: {"margin-right":"8px"}
                      }),
                      _createElementVNode("small", _hoisted_10, _toDisplayString(($data.card.apontamentos &&
											$data.card.apontamentos.find(
												(apontamento) => apontamento.ordem_de_producao?.name
											)?.ordem_de_producao.name) ||
										" -"), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            ($options.appStore.group_by.tipo_dashboard === 'Operadores da Lista')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_v_card, {
                    style: {"background-color":"#e0e0e0"},
                    elevation: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        title: "Recurso",
                        style: {"color":"black","font-weight":"bold","font-size":"18px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi mdi-cog-outline",
                            class: "text-truncate",
                            style: {"margin-right":"8px"}
                          }),
                          _createElementVNode("small", _hoisted_12, _toDisplayString(($data.card.apontamentos &&
											$data.card.apontamentos.find(
												(apontamento) => apontamento.desc_recurso
											)?.desc_recurso) ||
										" -"), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_v_card, {
            style: {"background-color":"#e0e0e0"},
            elevation: "5"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, {
                title: "Item",
                style: {"color":"black","font-weight":"bold","font-size":"18px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: "mdi mdi-toolbox-outline",
                    class: "text-truncate",
                    style: {"margin-right":"8px"}
                  }),
                  _createElementVNode("small", _hoisted_14, _toDisplayString(($data.card.apontamentos &&
									$data.card.apontamentos.find(
										(apontamento) => apontamento.ordem_de_producao?.item
									)?.ordem_de_producao.item) ||
								" -"), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_card, {
            style: {"background-color":"#e0e0e0"},
            elevation: "5"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, {
                title: "Data de Entrega",
                style: {"color":"black","font-weight":"bold","font-size":"18px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: "mdi mdi-calendar-range",
                    class: "text-truncate",
                    style: {"margin-right":"8px"}
                  }),
                  _createElementVNode("small", _hoisted_15, _toDisplayString($options.formatDateWithoutTime(
									$data.card.apontamentos &&
										$data.card.apontamentos.find(
											(apontamento) => apontamento.ordem_de_producao?.dt_entrega
										)?.ordem_de_producao.dt_entrega
								)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_card, {
            style: {"background-color":"#e0e0e0"},
            elevation: "5"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, {
                title: "Cliente",
                style: {"color":"black","font-weight":"bold","font-size":"18px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: "mdi mdi-briefcase-outline",
                    class: "text-truncate",
                    style: {"margin-right":"8px"}
                  }),
                  _createElementVNode("small", _hoisted_16, _toDisplayString(($data.card.apontamentos &&
									$data.card.apontamentos.find(
										(apontamento) => apontamento.ordem_de_producao?.cliente
									)?.ordem_de_producao.cliente) ||
								" -"), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      ($options.appStore.Modal_Apontamento_Aberto_Body)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["type"]))
}