<template>
    <v-card variant="outlined" :height="computed_display" width="100%" class="card_class mb-5" style="overflow-x: hidden;">
        <div class="card-circle mb-1">
            <div class="card-header-with-circle ">
                <div class="header-content" :style="display == 'xs' ? 'height: 51px;' : ' height: 49px'">
                    <p class="card-title ios-specific-class ">
                        {{ apontamento.name }} - {{ frappe.format(apontamento.ordem_de_producao.dt_entrega,
                            { "fieldtype": "Date" }) }}
                    </p>
                </div>
            </div>

            <div class="card-body pa-0" style="" @click="openModalParadaBody">
                <div class="image-container mt-2 ">
                    <img v-if="display != 'xs'"
                        src="https://img.freepik.com/vetores-premium/icone-de-sinal-de-parada-notificacoes-que-nao-fazem-nada_68708-468.jpg?w=360"
                        alt="Icone de Parada" class="round-image mt-4 ">
                </div>
                <v-row :class="display == 'xs' ? 'ma-2' : 'ma-2 pt-1'">
                    <v-col cols="6" sm="12" :class="display == 'xs' ? 'pt-0 pb-1 ' : 'py-1'">
                        <v-card class="border rounded-lg pa-2 mb-0 d-flex justify-space-between" flat color="#f0f0f0">
                            <v-icon v-if="display == 'xs'">mdi-account-hard-hat</v-icon>
                            <strong v-else>Operador:</strong>
                            <span class="text-truncate"
                            style="display: inline-block; max-width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{
                                    apontamento.operador.nome || ' -' }}</span>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="12" :class="display == 'xs' ? 'pt-0 pb-1' : 'py-1'">
                        <v-card width="100%" class="border rounded-lg pa-2 mb-0 d-flex justify-space-between" flat
                            color="#f0f0f0">
                            <v-icon v-if="display == 'xs'" size="20px" class=" pl-0">mdi-cog</v-icon>
                            <strong v-else>Recurso:</strong>
                            <span class="text-truncate"
                            style="display: inline-block; max-width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{
                                    apontamento.recurso.nome || ' -' }}</span>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="12" :class="display == 'xs' ? 'pt-0 pb-1' : 'py-1'">
                        <v-card width="100%" class="border rounded-lg pa-2 mb-0 d-flex justify-space-between" flat
                            color="#f0f0f0">
                            <v-icon v-if="display == 'xs'">mdi-pause</v-icon>
                            <strong v-else>Motivo Parada:</strong>
                            <span>{{ apontamento?.desc_motivo_parada || ' -' }}</span>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="12" :class="display == 'xs' ? 'pt-0 pb-0' : 'py-1'" v-if="display != 'xs'">
                        <v-card width="100%" class="border rounded-lg pa-2 mb-0 d-flex justify-space-between" flat
                            color="#f0f0f0">
                            <strong>OP:</strong>
                            <span>{{ apontamento.ordem_de_producao.name || ' -' }}</span>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="12" :class="display == 'xs' ? 'pt-0 pb-0' : 'py-1'">
                        <v-card width="100%" class="border rounded-lg pa-2 mb-1 d-flex justify-space-between" flat
                            color="#f0f0f0">
                            <v-icon v-if="display == 'xs'">mdi-clock</v-icon>
                            <strong v-else>Tempo:</strong>
                            <span class="time-value">{{ (duracao.split(':')[0] > 24 ? `${(duracao.split(':')[0] /
                                24).toFixed()} dias` : duracao) || ' -' }}</span>
                        </v-card>
                    </v-col>

                </v-row>


                <ModalMotivoDeParadaBody />
            </div>
        </div>
    </v-card>

</template>

<style scoped>
.div-principal {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px;
    width: 278px;
}

.card_class {
    /* margin-top: -20px; */
    /* Move o card para cima */
    border-radius: 15px;
    margin-bottom: 20px;
    max-width: 600px;
    flex: 1 0 21%;
    /* margin: 10px; */
    transition: none !important;
    border: 3px solid #BEBEBE;
    /* Remove qualquer animação */
}

.card_class:active,
.card_class:focus {
    box-shadow: none !important;
    /* Remove qualquer efeito visual ao clicar */
    transform: none !important;
    /* Remove transformações ao clicar */
}

.card_class:focus {
    outline: none !important;
    /* Remove o outline do foco */
}

.card_class:hover {
    background-color: transparent !important;
    /* Remover qualquer efeito de hover */
}

.normal_card {
    text-decoration: none;
    color: inherit;
}

.card-with-circle {
    display: flex;
    flex-direction: column;
    height: auto;
}

.card-header-with-circle {
    width: 100%;
    /* Ocupa toda a largura do card */
    align-items: center;
    /* Alinha os itens verticalmente */
    justify-content: space-between;
    /* Espaço entre os elementos */
    border-bottom: 1px solid #e0e0e0;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


.circle-text {
    color: white;
    font-size: 12px;
}

.header-content {
    display: flex;
    /* Ativa o flexbox */
    justify-content: center;
    /* Centraliza horizontalmente */
    align-items: center;
    /* Centraliza verticalmente */
    width: 100%;
    /* Garante que o contêiner ocupe toda a largura disponível */
    background-color: #f5f5f5;
    flex: 1;
    text-align: center;
}



.card-title {
    margin: 0;
    font-weight: bold;
}

.btn-plus {
    color: 6c757d;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 25px;
    cursor: pointer;
}

/* .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: white;
    margin-top: 5px;
    margin: 0;
    padding: 0;
    border: none;
} */

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#card {
    display: flex;
    justify-content: center;
}

.round-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.card_class:hover {
    cursor: pointer;
}

.info-container {
    width: 90%;
    text-align: left;
    margin-top: 20px;
}

.info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 9px;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: 1px solid #ddd;

}

@media (max-width: 600px) {
    .card_class {
        /* margin-left: 15px;
        left: -15px */
    }

    .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 15px
    }

    .round-image {
        margin-top: 15px;
        width: 60px;
        /* Tamanho maior para melhor visualização */
        height: 60px;
        object-fit: cover;
        /* Garante que a imagem se ajuste sem distorcer */
        border-radius: 50%;

    }

    .info-container {
        width: 95%;
        text-align: left;
        margin-top: 15px;
    }

    .info-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 9px;
        background-color: #f0f0f0;
        border-radius: 8px;
        border: 1px solid #ddd;

    }
}
</style>


<script>
import { useAppStore } from "../../stores/app";
import ModalMotivoDeParadaBody from "../components/modals/modal_motivo_de_parada_body.vue";
import { useDisplay } from 'vuetify'

export default {
    name: "appCardApontamentoParada",
    components: {
        ModalMotivoDeParadaBody
    },
    props: {
        apontamento: Object
    },
    data() {
        const duracao = this.calcularDuracaoDtInicio(this.apontamento.dt_inicio)
        return {
            display: useDisplay().name,
            interval: setInterval(this.adicionarUmSegundo, 1000),
            duracao,
            apontamento: this.apontamento,
        };
    },
    computed: {
        appStore() {
            return useAppStore();
        },

        computed_display() {
            switch (this.display) {
                case 'sm':
                    return '95%'
                case 'xs':
                    return '23vh'
                default:
                    return '94%'
            }
        }
    },
    beforeUnmount() {
        clearInterval(this.interval)
    },
    methods: {
        adicionarUmSegundo() {
            let [horas, minutos, segundos] = this.duracao.split(':').map(Number);
            segundos += 1;
            if (segundos === 60) {
                segundos = 0;
                minutos += 1;
            }
            if (minutos === 60) {
                minutos = 0;
                horas += 1;
            }
            const horasFormatadas = horas.toString().padStart(2, '0');
            const minutosFormatados = minutos.toString().padStart(2, '0');
            const segundosFormatados = segundos.toString().padStart(2, '0');
            this.duracao = `${horasFormatadas}:${minutosFormatados}:${segundosFormatados}`;
        },
        calcularDuracaoDtInicio(dataStr) {
            const dataInicial = moment(dataStr, 'YYYY-MM-DD HH:mm:ss');
            const agora = moment();
            const duracao = moment.duration(agora.diff(dataInicial));
            const horas = Math.floor(duracao.asHours()).toString().padStart(2, '0');
            const minutos = duracao.minutes().toString().padStart(2, '0');
            const segundos = duracao.seconds().toString().padStart(2, '0');
            // const days_or_hours = duracao.days() === 0 ? `${horas}:${minutos}:${segundos}` : duracao.days() + " " + "dias"
            return `${horas}:${minutos}:${segundos}`
        },
        openModalParadaBody() {
            this.appStore.openModalParadaBody();

            this.appStore.selectItem('apontamento', this.apontamento)
        },
    },
};
</script>
