<template>
     <v-card class="d-flex ma-2" :color="color" :height="tipo == null ? 90 : 180">
        <div  :style="colorStatusSensor" class="d-flex justify-center align-center" style="height: 100%; width: 40px;">
            <v-icon v-if="state_sensor == 'OFFLINE'" icon="mdi-lan-disconnect" color="white" size="31"></v-icon>
            <v-icon v-else icon="mdi-lan-connect" size="31"></v-icon>
        </div>

        <div class="pa-1" style="width: 100%;">
            <v-card flat color="transparent" class="d-flex flex-wrap flex-md-nowrap align-items-center justify-space-between" :height="tipo == null ? '100%': tipo == 'Manutenção' && observacao == '' ? '100%' : '50%'" style="width: 100%;">
                <span style="width: 68%;" class="duas-linhas">
                    {{ name }}
                </span>
                <span v-if="status != 'offline'" class="ml-1" >
                    {{ tempExecucao }}
                </span>
            </v-card>

            <v-card v-if="tipo == 'Produção'" color="transparent" flat class="d-flex align-items-center" width="100%" height="49%">
                <span v-if="op" class="duas-linhas" style="background-color: #3BA140; width: 100%;"> {{ op }} </span>
                <span v-else class="duas-linhas" style="background-color: #FFFB02; color: black; width: 100%;">O.P. NÃO INFORMADA</span>
            </v-card>

            <v-card v-if="tipo == 'Parada'" flat color="transparent"  class="d-flex align-items-center" width="100%" height="49%">
                <span v-if="op"
                    color="#388E3C" width="100%" class="duas-linhas" style="background-color: #3BA140; width: 100%;"> {{ motivo_de_parada }} </span>
                <span v-else class="duas-linhas" style="background-color: #FFFB02; color: black; width: 100%;">M.P. NÃO INFORMADO</span>
            </v-card>

            <v-card v-if="tipo == 'Setup'" flat color="transparent"  class="d-flex align-items-center" width="100%" height="49%">
                <span v-if="op" class="duas-linhas" style="background-color: #FFCA65; width: 100%;"> {{ op }} </span>
                <span v-else class="duas-linhas" style="background-color: #FFFB02; color: black; width: 100%;">O.P. NÃO INFORMADA</span>
            </v-card>

            <v-card v-if="tipo == 'Manutenção' && observacao" flat color="transparent"  class="d-flex align-items-center" width="100%" height="49%">
                <span v-if="observacao" class="duas-linhas" style="background-color: #5A9BEF; width: 100%;"> {{ observacao }} </span>
            </v-card>
        </div>
    </v-card>
</template>

<script>

import { CalculaTempoMaquina } from '../../../utils/funcoes';

export default {
    name: "card_recursos_status",
    data: () => {
        return {
            count: 0,
            time: 0,
            tempExecucao: 0
        }
    },
    props: {
        name: String,
        op: String,
        status: String,
        dt_inicio: String,
        color: String,
        motivo_de_parada: String,
        nome_operador: String,
        state_sensor: String,
        tipo: String,
        observacao: String
    },
    computed: {
        colorStatusSensor() {

            let backgroundColor;

            switch (this.state_sensor) {
                case 'OFFLINE':
                    backgroundColor = '#37474F';
                    break;
                case 'ACTIVE':
                    backgroundColor = '#43A047';
                    break;
                case 'IDLE':
                    backgroundColor = '#D50000';
                    break;
                default:
                    backgroundColor = 'grey';
            }

            return { backgroundColor: backgroundColor };
        }
    },

    methods: {
        inicia_count_machine() {
            clearInterval(this.time);
            setInterval(() => {
                this.tempoApontamento()
                this.time++;
            }, 1000);
        },
        tempoApontamento() {
            this.tempExecucao = CalculaTempoMaquina(
                new Date(this.dt_inicio)
            ).tempo
        },
    },
    mounted() {
        this.inicia_count_machine()
    }
}
</script>
<style scoped>
    .duas-linhas {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        border-radius: 5px;
        padding: 5px
    }

    span{
        font-weight: bold;
        border-radius: 5px;
        font-size: 28px;
    }


    @media (max-width: 1200px){
        span {
            font-size: 20px
        }
    }

    @media (max-width: 1000px){
        span {
            font-size: 13px
        }
        
    }

    @media (max-width: 1200px){
        span {
            font-size: 20px
        }
    }

    @media (max-width: 1000px){
        span {
            font-size: 13px
        }
        
    }


    @media(max-width: 850px) {
        .duas-linhas {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            border-radius: 5px;
        }
    } 

    @media (max-width: 500px) {
        span{
            font-size: 10px;
        }
        .duas-linhas {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            border-radius: 5px;
        }

    }
</style>