import { defineStore } from "pinia";
declare let frappe: any;

export const useAppStore = defineStore({
  id: "app",
  state: () => ({
    navbarImages: {
      apontamentos_producao: null,
      operadores: null,
      recursos: null,
      ordens_de_producao: null,
      motivos_de_parada: null,
    },
    selected_apontamento: null,
    selected_operador: null,
    selected_recurso: null,
    selected_ordem_de_producao: null,
    selected_motivo_de_parada: null,
    render: [],
    translator: {
      tab_to_selected: {
        apontamentos_producao: "apontamento",
        apontamentos_parada: "motivo_de_parada",
        ordens_de_producao: "ordem_de_producao",
        recursos: "recurso",
        operadores: "operador",
      },
      selected_to_tab: {
        apontamento: "apontamentos_producao",
        motivo_de_parada: "apontamentos_parada",
        ordem_de_producao: "ordens_de_producao",
        recurso: "recursos",
        operador: "operadores",
      }
    },
    type_view: "apontamentos_producao",
    selectedImage: null, // Adicionada para armazenar a imagem selecionada
    selectedImageOperador: null,
    setSelectedImageOrdem: null,
    selectedText: null,
    recursos: [],
    ordens_de_producao: [],
    operadores: [],
    apontamentos_producao: [],
    apontamentos_parada: [],
    motivos_de_parada: [],
    Modal_Motivo_De_Parada: false,
    Modal_Apontamento_Aberto_Head: false,
    Modal_Apontamento_Aberto_Body: false,
    Modal_finalizarOP: false,
    Modal_fechar_apontamento_aberto: false,
    Modal_motivo_de_parada_body: false,
    Modal_Op_head: false,
    lista: [],
    filter: { date: null, match: null },
  }),
  getters: {
    getSelectedImage: (state) => state.selectedImage,
    getTypeView: (state) => state.type_view,
  },
  actions: {
    persist: {
      enabled: true,
    },
    render_view() {
      console.log('render_view')

      // Obtém a aba ativa
      const tab = this.type_view;
      const filters = this.filter;
      // this.render = this[tab]; // Tem que mudar depois !!!!

      if (!filters.date && !filters.match) {
        this.render = this[tab];
        return;
      }
      switch (tab) {
        case "apontamentos_producao":
        case "apontamentos_parada":
          this.render = this[tab].filter((apontamento) => {
            // if (filters.date) {
            //   const data_filter = filters.date.split(" ")[0];
            //   const data_apontamento = apontamento.dt_inicio.split(" ")[0];
            //   if (JSON.stringify(data_apontamento) <= JSON.stringify(data_filter)) return true;

            // }
            if (filters.match) {
              const all_values = {
                operador: apontamento.operador.nome,
                recurso: apontamento.recurso.nome,
                apontamento: apontamento.name,
                quantidade: apontamento.quantidade,
                ordem_de_producao: apontamento.ordem_de_producao.name,
                dt_inicio: apontamento.dt_inicio,
              };
              const valuesArray = Object.values(all_values);
              console.log("apontamento ", apontamento);

              if (
                valuesArray.some((value) =>
                  String(value || "")
                    .toLowerCase()
                    .includes(filters.match.toLowerCase())
                )
              )
                return true;
            }
            return false;
          });
          break;

        case "ordens_de_producao":
          this.render = this[tab].filter((op) => {
            if (filters.date) {
              const data_filter = filters.date.split(" ")[0];
              const data_op = op.dt_entrega;
              if (JSON.stringify(data_op) <= JSON.stringify(data_filter)) return true;
            }
            if (filters.match) {
              console.log("apontamento ordem ", op);

              const all_values = {
                ordem_de_producao: op.name,
                dt_entrega: op.dt_entrega.split(" ")[0],
                recurso:
                  op.apontamentos && op.apontamentos.length !== 0
                    ? op.apontamentos[0].recurso
                    : undefined,
                operador:
                  op.apontamentos && op.apontamentos.length !== 0
                    ? op.apontamentos[0].operador
                    : undefined,
                motivo_de_parada:
                  op.apontamentos && op.apontamentos.length !== 0
                    ? op.apontamentos[0].motivo_de_parada
                    : undefined,
              };

              const valuesArray = Object.values(all_values);
              if (
                valuesArray.some((value) =>
                  String(value || "")
                    .toLowerCase()
                    .includes(filters.match.toLowerCase())
                )
              )
                return true;
            }
            return false;
          });

          break;

        case "recursos":
        case "operadores":
          this.render = this[tab].filter((apontamento) => {
            if (filters.match) {
              const all_values =
                tab === "recursos"
                  ? { recurso: apontamento.nome }
                  : { operador: apontamento.nome };
              const valuesArray = Object.values(all_values);
              if (
                valuesArray.some((value) =>
                  String(value || "")
                    .toLowerCase()
                    .includes(filters.match.toLowerCase())
                )
              )
                return true;
            }

            return false;
          });
          break;

        default:
          console.error("Aba não implementada:", tab);
      }
    },
    async initial_fetch() {
      const res = await frappe.call(
        "nxlite.nx_producao.page.apontamento_web.controller.initial_fetch"
      );
      console.log(await res);

      this.apontamentos_producao = await res.message.apontamentos_producao;
      this.ordens_de_producao = await res.message.ordens_de_producao;
      this.operadores = await res.message.operadores;
      this.apontamentos_parada = await res.message.apontamentos_parada;
      this.recursos = await res.message.recursos;
      this.motivos_de_parada = await res.message.motivos_de_parada;
      this.render = this.apontamentos_producao;
    },
    selectItem(type, item) {
      this[`selected_${type}`] = item;
    },
    async break_selected_apt() {
      console.log(this.selected_motivo_de_parada);
      await frappe
        .call(
          this.selected_apontamento.tipo == "Parada"
            ? "nxlite.nx_producao.page.apontamento_web.controller.close_cur_apt_and_create_producao"
            : "nxlite.nx_producao.page.apontamento_web.controller.close_cur_apt_and_create_parada",
          {
            apontamento_name: this.selected_apontamento.name,
            motivo_de_parada: this.selected_motivo_de_parada ? this.selected_motivo_de_parada.name : undefined,
          }
        )
        .then(() => {
          this.selected_apontamento = null;
          this.selected_motivo_de_parada = null;
          frappe.toast({
            indicator: "green",
            message: "Quebra para produção realizada com sucesso.",
          });
        });
    },
    update_store_operador(operador) {

      console.log('not implemented', operador)

      if (operador.status == "Inativo") {
        console.log("Inativo")
        this.operadores = this.operadores.filter(o => o.name != operador.name)
        return
      }
      if (operador.status == "Ativo") {
        console.log("Ativo:", operador)
        const found = this.operadores.some(o => o.name == operador.name)
        if (!found) {
          console.log("Não encontramos o elemento dentro do array")
          this.operadores == this.operadores.push({ ...operador, apontamentos: [] })
          return;
        }
        console.log("Existe o elemento dentro do array")
        this.operadores = this.operadores.map(o => o.name == this.operadores.name ? { ...operador, apontamentos: o.apontamentos } : o)
        console.log("Novo operador: ", this.operadores)
      }
    },
    update_store_recurso(recurso) {
      console.log('not implemented', recurso)
      console.log(this.recursos)
      if (recurso.status == "Inativo") {
        this.recursos = this.recursos.filter(r => r.name != recurso.name)
        return
      }

      if (recurso.status == "Ativo") {
        const found = this.recursos.some(r => r.name == recurso.name)
        if (!found) {
          console.log('Não há o elemento dentro do array (not implemented)')
          this.recursos.push({ ...recurso, apontamentos: [] })
          return
        }
        console.log('Há um elemento igual dentro do array')
        this.recursos = this.recursos.map(r => r.name == recurso.name ? { ...recurso, apontamentos: r.apontamentos } : r)
        console.log("new this.recursos", this.recursos)
      }
    },
    update_store_ordem_de_producao(op) {
      console.log('not implemented', op)
      if (op.status_op == "Fechado") {
        console.log("Fechado")
        this.ordens_de_producao = this.ordens_de_producao.filter(p => p.name != op.name)
        return
      }
      if (op.status_op == "Aberto") {
        const found = this.ordens_de_producao.some(p => p.name == op.name)
        if (!found) {
          console.log("elemento não encontrado no array")
          this.ordens_de_producao.push({ ...op, apontamentos: [] })
        }

        this.ordens_de_producao = this.ordens_de_producao.map(p => p.name ? { ...op, apontamentos: p.apontamentos } : p)
      }
    },
    update_store_apontamento(detailed_apt) {
      console.log("detailed_apt", detailed_apt)
      if (detailed_apt.status == "Fechado") {
        this.apontamentos_producao = this.apontamentos_producao ? this.apontamentos_producao.filter(
          (apt) => apt.name != detailed_apt.name
        ) : [];
        this.apontamentos_parada = this.apontamentos_parada ? this.apontamentos_parada.filter(
          (apt) => apt.name != detailed_apt.name
        ) : [];
        this.ordens_de_producao = this.ordens_de_producao ? this.ordens_de_producao.map((op) => ({
          ...op,
          apontamentos: op.apontamentos && op.apontamentos.length ? op.apontamentos.filter(
            (apt) => apt.name != detailed_apt.name
          ) : [],
        })) : [];
        this.recursos = this.recursos ? this.recursos.map((recurso) => ({
          ...recurso,
          apontamentos: recurso.apontamentos && recurso.apontamentos.length ? recurso.apontamentos.filter(
            (apt) => apt.name != detailed_apt.name
          ) : [],
        })) : [];
        this.operadores = this.operadores ? this.operadores.map((operador) => ({
          ...operador,
          apontamentos: operador.apontamentos && operador.apontamentos.length ? operador.apontamentos.filter(
            (apt) => apt.name != detailed_apt.name
          ) : [],
        })) : [];
        this.render_view();
        return;
      }
      const update_apt_stores = (store, apontamento) => {
        if (this[store].some((apt) => apt.name == apontamento.name)) {
          this[store] = this[store].map((apt) =>
            apt.name == apontamento.name ? apontamento : apt
          );
        } else {
          this[store].push(apontamento);
        }
      };
      const update_param_stores = (store, apontamento) => {
        this[store] = this[store].map((e) => {
          if (apontamento.ordem_de_producao.name != e.name && apontamento.operador.name != e.name && apontamento.recurso.name != e.name) return e; //O apontamento que chegou não corresponde à esse elemento.
          if (!e.apontamentos.length) return ({ ...e, apontamentos: [apontamento] }) //Não há apontamento para o elemento dessa store.
          if (e.apontamentos.some((apt) => apt.name == apontamento.name)) {
            const new_apontamentos = e.apontamentos.map(apt => apt.name == apontamento.name ? apontamento : apt)
            return ({ ...e, apontamentos: new_apontamentos })
          }
          const new_apontamentos = e.apontamentos.unshift(apontamento)
          return ({ ...e, apontamentos: new_apontamentos })
        })
      };
      if (detailed_apt.tipo == "Produção")
        update_apt_stores("apontamentos_producao", detailed_apt);
      if (detailed_apt.tipo == "Parada")
        update_apt_stores("apontamentos_parada", detailed_apt);
      update_param_stores("ordens_de_producao", detailed_apt);
      update_param_stores("recursos", detailed_apt);
      update_param_stores("operadores", detailed_apt);
      this.render_view();
    },
    openModalMotivoDeParada() {
      this.Modal_Motivo_De_Parada = true;
    },
    closeModalMotivoDeParada() {
      this.Modal_Motivo_De_Parada = false;
    },
    openModalApontamentoAbertoHead() {
      // this.selected_apontamento = apontamento;
      this.Modal_Apontamento_Aberto_Head = true;
    },
    closeModalApontamentoAbertoHead() {
      this.Modal_Apontamento_Aberto_Head = false;
    },
    openModalApontamentoAbertoBody() {
      this.Modal_Apontamento_Aberto_Body = true;
    },
    closeModalApontamentoAbertoBody() {
      this.Modal_Apontamento_Aberto_Body = false;
    },
    openModalFinalizarOP() {
      this.Modal_finalizarOP = true;
    },
    openModalParadaBody() {
      this.Modal_motivo_de_parada_body = true;
    },
    closeModalFinalizarOP() {
      this.Modal_finalizarOP = false;
    },
    openModalFecharApontamentoAberto() {
      this.Modal_fechar_apontamento_aberto = true;
    },
    closeModalFecharApontamentoAberto() {
      this.Modal_fechar_apontamento_aberto = false;
    },
    openModalOpHead() {
      this.Modal_Op_head = true
    },
    closeModalOpHead() {
      this.Modal_Op_head = false
    },
    toggleModals(selecionado) {
      if (selecionado == "finalizar") {
        this.Modal_finalizarOP = true;
        return;
      }
      if (selecionado === "motivoParada") {
        this.Modal_Motivo_De_Parada = true;
        return;
      }
      if (selecionado == "fechar") {
        this.Modal_fechar_apontamento_aberto = true;
        return;
      }
      if (selecionado == "confirmar") {
        this.Modal_fechar_apontamento_aberto = true;
        return;
      }
    },
    async close_ordem_de_producao(name) {
      const response = await frappe.call(
        "nxlite.nx_producao.page.apontamento_web.controller.finish_ordem_de_producao",
        {
          ordem_de_producao_name: name,
        }
      );
      console.log(await response);
      frappe.toast({
        indicator: "green",
        message: "Ordem de produção finalizada com sucesso.",
      });
    },
    async create_apontamento() {
      if (
        this.selected_operador &&
        this.selected_recurso &&
        this.selected_ordem_de_producao
      ) {
        const response = await frappe.call(
          "nxlite.nx_producao.page.apontamento_web.controller.open_apontamento",
          {
            operador: this.selected_operador.name,
            recurso: this.selected_recurso.name,
            ordem_de_producao: this.selected_ordem_de_producao.name,
            motivo_de_parada: this.selected_motivo_de_parada
              ? this.selected_motivo_de_parada.name
              : undefined,
          }
        );
        console.log(await response);
        if (typeof (await response.message) == "string") {
          frappe.toast({
            indicator: (await response.message.includes(
              "Já há um apontamento aberto com essas configurações"
            ))
              ? "blue"
              : "red",
            message: await response.message,
          });
          return;
        }
        this.selected_ordem_de_producao = null;
        // this.selected_recurso = null;
        // this.selected_operador = null;
        this.selected_motivo_de_parada = null;
        console.log("seleções limpadas");
        frappe.toast({
          indicator: "green",
          message: "Apontamento criado com sucesso.",
        });
      }
    },
    render_filter_by_selecteds() {
      console.log('not implemented',
        this.selected_apontamento,
        this.selected_motivo_de_parada,
        this.selected_ordem_de_producao,
        this.selected_operador,
        this.selected_recurso,
        this.render
      )
      if (this.type_view != 'apontamentos_producao' && this.type_view != 'apontamentos_parada'
        || !this.selected_operador && !this.selected_recurso) return;

      console.log('this.type_view', this.type_view)
      console.log('Card atual: ',this[this.type_view])
      const filter = (this.selected_operador && this.selected_recurso) 
      ? (apt) => (apt.recurso.name == this.selected_recurso.name && apt.operador.name == this.selected_operador.name)
      : (
        this.selected_operador ? (apt) => (apt.operador.name == this.selected_operador.name) : (apt) => (apt.recurso.name == this.selected_recurso.name)
      )
      console.log("this.selected_operador: ", this.selected_operador)
      console.log("this.selected_recurso: ", this.selected_recurso)

      console.log(filter)
      this.render = this[this.type_view].filter(filter)
      console.log(this.render)
      console.log("TESTEEEE")

    },
    async close_apontamento(name, pecas_boas, pecas_refugadas) {
      const returned = await frappe.call(
        "nxlite.nx_producao.page.apontamento_web.controller.close_apontamento",
        {
          name: name,
          pecas_boas: pecas_boas || 0,
          pecas_refugadas: pecas_refugadas || 0,
        }
      );
      frappe.toast({
        indicator:
          (await returned.message) == "Não foi possível fechar o apontamento."
            ? "red"
            : "green",
        message:
          (await returned.message) == "Não foi possível fechar o apontamento."
            ? "O apontamento solicitado não está disponível para fechamento."
            : "Apontamento fechado com sucesso.",
      });
    },
    tour() {
      console.log('ENTREEEEEEEEEEI NO TOUR APP')
			frappe.call({
				method: 'nxlite.nx_producao.page.apontamento_web.controller.start_tour',
				args: {
					tour_name: 'Apontamento-WEB'
				},
				callback: function (response) {
					// Opcional: Aqui você pode adicionar lógica para lidar com o retorno, caso necessário
					console.log('RESPOSTA DA FUNÇÃO TOUR',response.message);
				}
			});
		}
  },
});
