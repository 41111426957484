<template>
    <div>
        <!-- <Card/> -->
        <Modal/>
        <OverlayEvents/>
        <Navbar/>
    </div>
</template>

<script>
import { useAppStore } from './stores/app'
import Card from './app/components/card.vue'
import Modal from './app/components/modal.vue';
import OverlayEvents from './app/components/overlay_events.vue';
import Navbar from './app/components/navbar.vue';
export default {
    name: 'OPxRecApp',
    components: {
        Card,
        Modal,
        OverlayEvents,
        Navbar,
    },
    data() {
        return {
            count: 0
        }
    },
    computed: {
        appStore() {
            return useAppStore();
        },

    },
    async beforeMount() {
        await this.appStore.initial_fetch()
    },
    async mounted() {
        frappe.realtime.on('apontamento', (data) => {
            console.log('Evento apontamento recebido:', data);
            this.appStore.update_apontamento(data);
        });
        // frappe.realtime.on('Operador', (data) => {console.log('not implemented', data)})
    }
    
}
</script>
<style></style>
