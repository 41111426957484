import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d909c48b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Card = _resolveComponent("Card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createCommentVNode(" formatação dos cards "),
        _createVNode(_component_v_row, {
          justify: "start",
          class: "mt-2"
        }, {
          default: _withCtx(() => [
            ($options.appStore.cards)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.appStore.cards, (card, index) => {
                  return (_openBlock(), _createBlock(_component_v_col, _mergeProps({
                    class: "pt-0 pb-0 mt-0 mb-4",
                    style: {"height":"70%","justify-items":"center !important"},
                    cols: "12",
                    sm: "6",
                    md: "4",
                    lg: "3",
                    xxl: "2",
                    key: card.name
                  }, card), {
                    default: _withCtx(() => [
                      _createVNode(_component_Card, { card: card }, null, 8 /* PROPS */, ["card"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}