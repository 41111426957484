<template>
	<div class="nav">
		<v-card>
			<v-layout>
				<v-app-bar color="primary" prominent v-if="appStore.cards">
					<v-container>
						<v-row align="center" justify="start" no-gutters>
							<!-- Coluna da imagem -->
							<v-col cols="auto">
								<img
									src="/assets/nxlite/vue/operadores-x-recurso/app/components/nx_circulo_azul.png"
									alt="imagem nxlite"
									style="max-height: 50px"
								/>
							</v-col>

							<!-- Coluna do texto -->
							<v-col cols="auto" class="ml-2">
								<a
									href="https://nxlite.com.br/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<small class="text-h5" style="color: white; margin-left: -24px"
										>www.nxlite.com.br</small
									>
								</a>
							</v-col>
						</v-row>
					</v-container>
					<v-container>
						<v-row align="center" justify-center>
							<v-col>
								<small class="text-h5 text-center">{{ selectedDashboardName }}</small>
							</v-col>
						</v-row>
					</v-container>

					<!-- botão de ampliação -->
					<v-row
						align="center"
						justify="end"
						:class="{ 'icone-ampliacao': appStore.modal }"
						class="icon-container"
					>
						<v-col cols="auto">
							<v-btn
								style="height: 60px; width: 60px; background-color: primary"
								density="compact"
								icon="mdi mdi-aspect-ratio"
								class="icon-large button-spacing"
								@click="toggleFullScreen"
							></v-btn>
						</v-col>
					</v-row>
				</v-app-bar>
				<v-main> </v-main>
			</v-layout>
		</v-card>
	</div>
</template>

<script>
import Card from "./card.vue";
import { useAppStore } from "../../stores/app";

export default {
	name: "OverlayEvents",
	components: { Card },
	computed: {
		appStore() {
			return useAppStore();
		},
		selectedDashboardName() {
			return this.appStore.selectedDashboardName;
		},
	},
	// funcao para abrir e fechar do botão de ampliacao
	methods: {
		toggleFullScreen() {
			const doc = document;
			const element = doc.documentElement;

			!doc.fullscreenElement
				? element.requestFullscreen
					? element.requestFullscreen()
					: element.webkitRequestFullscreen
					? element.webkitRequestFullscreen()
					: element.msRequestFullscreen && element.msRequestFullscreen()
				: doc.exitFullscreen
				? doc.exitFullscreen()
				: doc.webkitExitFullscreen
				? doc.webkitExitFullscreen()
				: doc.msExitFullscreen && doc.msExitFullscreen();
		},
	},
};
</script>

<style scoped>
.icon-large {
	font-size: 26px;
}

.icon-container {
	padding-right: 16px;
	padding-bottom: 5px;
	padding-top: 5px;
}
.nav {
	margin-bottom: 300px;
}
</style>
